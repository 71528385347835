import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { MaterialSymbol } from '@marty-js/design/src/atoms/material-symbol';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { HeaderLinkLevel1 } from '../../utils/headerFormatter';

import { SecondaryNavItems } from './secondary-nav-items';

const NavBar = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
    `,
  )}
`;

const NavItem = styled.li`
  border-bottom: 1px solid #dfe3ea;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--title-foreground);
  padding: 12px 16px;

  &.emphasize {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      align-items: center;
      flex-direction: row;
      justify-items: flex-start;
      padding: 0 10px;
      margin-bottom: 0px;
      line-height: 25px;
      top: 0px;
      height: 65px;
      border-bottom: 3px solid transparent;
    `,
  )}
`;

const NavDrop = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: nowrap;
  list-style: none;
  text-transform: initial;

  &:first-of-type li {
    margin-left: 0;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background-color: var(--background-color);
      box-shadow: 0px 50px 50px 0px
          ${(props) => {
            return `${props.theme.colors.foreground}19`;
          }},
        inset -5px 5px 5px 0px ${(props) => {
            return `${props.theme.palette.black}15`;
          }};

      display: none;
      position: absolute;
      justify-content: flex-start;
      left: 0px;
      margin: 20px 0;
      overflow: hidden;
      padding-left: calc((100vw - 1080px) / 2);
      top: 65px;
      transition: background 1s;
      white-space: nowrap;
      width: 105%;

      ${NavItem}:hover & {
        display: flex;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: row;

        li {
          width: auto;
        }
      }
    `,
  )};
`;

const NavNoLink = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  cursor: default;
`;

const NavIcon = styled(MaterialSymbol)``;

interface Props {
  navLinks: HeaderLinkLevel1[];
  onClick?: () => void;
}

export const PrimaryNavItems: React.FC<Props> = ({ navLinks, onClick }: Props) => {
  const [isCurrentUrlInDownload, setIsCurrentUrlInDownload] = React.useState(false);
  const LinkComponent = useLinkComponent();

  const downloadRegex = useMemo<RegExp>(() => /telecharger/i, []);

  useEffect(() => {
    setIsCurrentUrlInDownload(downloadRegex.test(window.location.href));
  }, [downloadRegex]);

  return (
    <NavBar>
      {navLinks.map((navItem) => (
        <NavItem
          key={navItem.order}
          className={
            navItem?.emphasize || (isCurrentUrlInDownload && downloadRegex.test(navItem.url)) ? 'emphasize' : ''
          }
        >
          {navItem.url ? (
            <LinkComponent href={navItem.url} onClick={onClick}>
              {navItem.iconSlug && <NavIcon symbol={navItem.iconSlug} />}
              {navItem.title}
            </LinkComponent>
          ) : (
            <NavNoLink>
              {navItem.iconSlug && <NavIcon symbol={navItem.iconSlug} />}
              {navItem.title}
            </NavNoLink>
          )}
          {navItem.subLinks && (
            <NavDrop>
              <SecondaryNavItems columns={navItem.subLinks} />
            </NavDrop>
          )}
        </NavItem>
      ))}
    </NavBar>
  );
};
