import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import ArrowDown from '@marty-js/design/src/icons/arrowDown';
import { transparentize } from 'polished';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { HeaderLinkLevel1 } from '../../utils/headerFormatter';

import { SecondarySubNav } from './secondary-sub-nav';

const NavItem = styled.li`
  align-items: center;
  border-bottom: 1px solid #dfe3ea;
  display: flex;
  flex-flow: wrap;
  font-weight: bold;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1;
  padding: 12px 16px;

  &.emphasize {
    color: ${(props) => props.theme.colors.primary};
  }

  a {
    transition: color 0.3s;
  }

  &.mod-active > a,
  &:hover > a {
    color: ${(props) => props.theme.colors.primary};
  }

  svg {
    transition: transform 0.2s;
    transform: rotate(0deg);
    fill: ${(props) => (props.theme.isDark ? 'white' : transparentize(0.84, props.theme.colors.foreground))};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      align-items: center;
      border: none;
      flex-direction: row;
      flex-flow: initial;
      justify-items: flex-start;
      padding: 15px 20px;
      margin-bottom: 0px;
      top: 0px;

      :hover {
        svg {
          fill: ${(props) => props.theme.colors.primary};
          transform: rotate(180deg);
        }
      }
    `,
  )}
`;

const NavDrop = styled.div`
  background: none;
  display: flex;
  margin: 12px 0 0;
  flex: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background-color: var(--background-color);
      border-bottom: solid 1px ${(props) => transparentize(0.84, props.theme.colors.foreground)};
      display: none;
      justify-content: flex-start;
      flex: initial;
      left: 0px;
      margin: 0;
      overflow: hidden;
      padding-left: calc((100vw - 1080px) / 2);
      position: absolute;
      top: 40px;
      width: 105%;

      ${NavItem}:hover & {
        display: flex;
      }
    `,
  )};

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;

      &.mod-open {
        display: flex;
      }
    `,
  )};
`;

const NavNoLink = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  cursor: default;
`;

const Dropdown = styled.button`
  transition: all 0.3s;
  transform: initial;
  width: 30px;

  &:hover svg {
    ${(props) => props.theme.colors.primary}
  }

  &.mod-reverse {
    transform: rotate(180deg);

    svg {
      fill: ${(props) => props.theme.colors.primary};
      margin: initial;
    }
  }
`;
interface Props {
  navLinks: HeaderLinkLevel1[];
  onClick?: () => void;
}

interface DropdownProps {
  [key: number]: boolean;
}

export const PrimarySubNav: React.FC<Props> = ({ navLinks, onClick }: Props) => {
  const [hover, setHover] = useState(null);
  const [dropdownsOpen, setDropdownOpen] = useState<DropdownProps>({});
  const [width, setWidth] = useState(0);
  const LinkComponent = useLinkComponent();

  const changeHover = (navItem: any) => {
    if (width >= DEVICE_SIZE.LARGE) {
      setHover(navItem);
    }
  };

  const toggleDropdown = (element: any, navItem: number) => {
    dropdownsOpen[navItem] = !dropdownsOpen[navItem] ?? true;
    setDropdownOpen({ ...dropdownsOpen });

    setTimeout(() => {
      document.getElementById(`navitem-${navItem}`).scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  return (
    <>
      {navLinks.map((navItem) => (
        <NavItem
          key={navItem.order}
          onMouseEnter={() => changeHover(navItem.order)}
          onMouseLeave={() => changeHover(null)}
          className={(dropdownsOpen[navItem.order] ? 'mod-active' : '') + (navItem.emphasize ? 'emphasize' : '')}
          id={`navitem-${navItem.order}`}
        >
          {navItem.url ? (
            <LinkComponent href={navItem.url} onClick={onClick}>
              {navItem.title}
            </LinkComponent>
          ) : (
            <NavNoLink>{navItem.title}</NavNoLink>
          )}
          {navItem.subLinks ? (
            <Dropdown
              aria-label={navItem.title}
              className={dropdownsOpen[navItem.order] ? 'mod-reverse' : ''}
              onClick={(e: any) => toggleDropdown(e, navItem.order)}
            >
              <ArrowDown width={10} height={10} />
            </Dropdown>
          ) : null}
          {navItem.subLinks && (
            <NavDrop className={dropdownsOpen[navItem.order] || hover ? 'mod-open' : ''}>
              <SecondarySubNav columns={navItem.subLinks} />
            </NavDrop>
          )}
        </NavItem>
      ))}
    </>
  );
};
