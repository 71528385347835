import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';

// eslint-disable-next-line import/no-cycle
import { MaterialSymbol } from '@marty-js/design/src/atoms/material-symbol';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { HeaderLinkLevel2 } from '../../utils/headerFormatter';

const DropDownColumn = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin-right: 40px;
      white-space: nowrap;
    `,
  )};
`;

const SecondNavItem = styled.li`
  color: ${(props) => props.theme.colors.title};
  font-weight: 600;
  font-size: 13px;
  line-height: 29px;
  padding: 2px;
  margin-left: 40px;

  a {
    display: block;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border-bottom: 2px solid transparent;
      font-size: 1em;
      padding: 0;

      &:hover {
        border-bottom: 2px solid;
      }
    `,
  )};
`;

const NavIcon = styled(MaterialSymbol)``;

const SecondNavItemUl = styled.ul`
  list-style: none;
`;

const StyledNavNoLink = styled.span``;

interface Props {
  columns: HeaderLinkLevel2[][];
  onClick?: () => void;
}

export const SecondaryNavItems: React.FC<Props> = ({ columns, onClick }: Props) => {
  const LinkComponent = useLinkComponent();

  return (
    <>
      {columns.map((column: HeaderLinkLevel2[], index) => (
        <DropDownColumn key={index}>
          <SecondNavItemUl>
            {column.map((link: HeaderLinkLevel2) => (
              <SecondNavItem key={link.title}>
                {link.url ? (
                  <LinkComponent href={link.url} onClick={onClick}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </LinkComponent>
                ) : (
                  <StyledNavNoLink onClick={onClick}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </StyledNavNoLink>
                )}
              </SecondNavItem>
            ))}
          </SecondNavItemUl>
        </DropDownColumn>
      ))}
    </>
  );
};
