// import React, { useContext, useEffect, useRef, useState } from 'react';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { formatNavLinksFromComponent, HeaderLinkLevel1 } from '@marty-js/sdk/src/utils/headerFormatter';
import Facebook from '@marty-js/design/src/icons/facebook';
import Instagram from '@marty-js/design/src/icons/instagram';
import Youtube from '@marty-js/design/src/icons/youtube';
import Twitter from '@marty-js/design/src/icons/twitter';
import TikTok from '@marty-js/design/src/icons/tiktok';
import ClubicText from '@marty-js/design/src/icons/clubicText';
import Linkedin from '@marty-js/design/src/icons/linkedin';

import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
// import { AuthContext } from '@marty-js/sdk/src/utils/AuthContext';
// import { useAdContext } from '@marty-js/sdk/src/ads/AdContext';
// import { useStickyContext } from '@marty-js/design/src/utils/sticky';

// const Newsletter = styled.section`
//   background-color: ${(props) => props.theme.palette.red};
//   bottom: 0;
//   box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
//   color: ${(props) => props.theme.palette.white};
//   left: 0;
//   position: fixed;
//   transform: translateY(100%);
//   transition: transform 0.3s;
//   right: 0;
//   z-index: 1000;

//   &.mod-opened {
//     transform: translateY(0);
//   }

//   &.mod-opened.mod-fixed {
//     position: relative;
//   }

//   ${mq.lte(
//     DEVICE_SIZE.LARGE,
//     css`
//       text-align: center;
//     `,
//   )}
// `;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: var(--spacer-m-fluid) var(--spacer-s);

  &.mod-column {
    text-align: center;
    gap: var(--spacer);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
      gap: var(--spacer-l);
      margin: auto;
      max-width: 1200px;

      &.mod-column {
        align-items: initial;
        flex-direction: column;
        text-align: initial;
      }
    `,
  )}
`;

// const NewsletterLeft = styled.div`
//   ${mq.gte(
//     DEVICE_SIZE.LARGE,
//     css`
//       width: 50%;
//     `,
//   )}
// `;

// const NewsletterTitle = styled.div`
//   font-family: ${(props) => props.theme.typography.primaryFont};
//   font-size: 18px;
//   font-weight: bold;
//   margin: 0 0 var(--spacer-s) 0;

//   ${mq.gte(
//     DEVICE_SIZE.LARGE,
//     css`
//       font-size: 32px;
//     `,
//   )}
// `;

// const NewsletterText = styled.p`
//   line-height: 1.62;

//   ${mq.lte(
//     DEVICE_SIZE.LARGE,
//     css`
//       font-size: 14px;
//       margin: 0 0 var(--spacer-s) 0;
//     `,
//   )}
// `;

// const NewsletterRight = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: var(--spacer-s);

//   ${mq.gte(
//     DEVICE_SIZE.LARGE,
//     css`
//       width: 50%;
//     `,
//   )}
// `;

// const NewsletterForm = styled.form`
//   align-items: stretch;
//   display: flex;
//   gap: var(--spacer-s);
// `;

// const NewsletterInput = styled.input`
//   background-color: ${(props) => props.theme.palette.white};
//   border: none;
//   color: ${(props) => props.theme.palette.black};
//   border-radius: 6px;
//   padding: 10px;
//   width: 80%;

//   &:focus {
//     outline: none;
//   }
// `;

// const NewsletterButton = styled.button`
//   border: solid 2px white;
//   border-radius: 6px;
//   color: white;
//   font-family: ${(props) => props.theme.typography.primaryFont};
//   font-weight: bold;
//   position: relative;
//   text-align: center;
//   transition: background-color 0.3s;
//   padding: 12px;
// `;

// const NewsletterClose = styled.span`
//   cursor: pointer;
//   position: absolute;
//   right: 25px;
//   top: 25px;
//   font-size: 30px;
// `;

// const NewsletterUnsubscribe = styled.p`
//   font-size: 13px;
//   line-height: 1.62;

//   a {
//     font-weight: bold;
//     text-decoration: underline;
//   }
// `;

const FooterWrapper = styled.footer`
  background-color: ${(props) => (props.theme.isDark ? 'initial' : props.theme.palette.blackAlt)};
  color: ${(props) => props.theme.palette.white};
  line-height: 1.62;
`;

const FooterLogo = styled.div``;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-template-columns: 60px 240px 60px 60px 1fr 60px 60px 240px 60px;
    `,
  )}
`;

const FooterLeft = styled.div`
  grid-column: 2 / 3;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      padding: 0 var(--spacer-m) 0 0;
      border-right: 1px solid white;
      grid-column: 1 / 7;
    `,
  )}
`;

const FooterTitle = styled.div`
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 var(--spacer-s) 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 22px;
    `,
  )}
`;

const FooterText = styled.p``;

const FooterRight = styled.div`
  grid-column: 7 / span 3;
  padding: 0 0 0 var(--spacer-m);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: var(--spacer-s) 0 0 0;
      grid-column: 2 / 3;
      padding: initial;
      position: relative;

      &::after {
        bottom: -10px;
        content: '';
        height: 1px;
        background-color: white;
        margin: 0 auto;
        width: 40%;
        position: absolute;
        left: 0;
        right: 0;
      }
    `,
  )}
`;

const FooterList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-xs);
  list-style: none;
`;

const FooterListItem = styled.li`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
  }
`;

const FooterSocials = styled.div`
  display: flex;
  gap: var(--spacer-s);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      flex-flow: wrap;
      justify-content: center;
      order: 1;
    `,
  )}
`;

const FooterSocialItem = styled.div`
  align-items: center;
  background: white;
  border-radius: 50%;
  border: solid 1px white;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: background 0.3s;
  width: 40px;

  svg,
  path {
    transition: fill 0.3s;
  }

  &:hover {
    background: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : props.theme.palette.blackAlt)};

    svg,
    path {
      fill: white;
    }
  }
`;

const FooterLegal = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: var(--spacer-m-to-xs);

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
      font-size: 14px;
      margin: var(--spacer-s) 0 0 0;
    `,
  )}
`;

const FooterCopyright = styled.p``;

const FooterLegalLinks = styled.ul`
  display: flex;
  gap: var(--spacer-m-to-xs);
  flex-flow: wrap;
  justify-content: center;
  list-style: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      justify-content: initial;
    `,
  )}
`;

const FooterLegalLink = styled.li`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
  }
`;

// const Message = styled.p`
//   font-size: 13px;
//   line-height: 1.62;
// `;

interface Props {
  footerComponent: any;
}

export const Footer: React.FC<Props> = ({ footerComponent }) => {
  const footerRef = useRef(null);
  // const newsletterRef = useRef(null);
  // const newsletterFormRef = useRef(null);
  // const emailRef = useRef(null);

  // const { email } = useContext(AuthContext);
  // const adContext = useAdContext();
  // const { sticky } = useStickyContext();

  // const [isNewsletterOpened, setIsNewsletterOpened] = useState(false);
  // const [isNewsletterForceClosed, setIsNewsletterForceClosed] =
  // useState(adContext?.tags?.includes('nopub') && !sticky);
  // const [isFixedNewsletter, setIsFixedNewsletter] = useState(false);
  // const [isNewsletterTriggeredLessThan24HoursAgo, setIsNewsletterTriggeredLessThan24HoursAgo] = useState(false);
  // const [scrollY, setScrollY] = useState(0);
  // const [classes, setClasses] = useState('');

  // const [success, setSuccess] = useState('');
  // const [error, setError] = useState('');

  const copyrightYear = new Date();
  const t = useTranslation();

  const LinkComponent = useLinkComponent();
  const { currentTheme } = useThemeSwitcher();

  // const registerNewsletter = (e: any) => {
  //   e.preventDefault();
  //   fetch('https://services.ownpage.fr/v1/subscribe/288e97776c3e41e8', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       form_key: '8a9d9acf76074efe',
  //       email: emailRef.current?.value,
  //     }),
  //     headers: new Headers({
  //       'Content-Type': 'application/json; charset=UTF-8',
  //     }),
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       return data.success ? setSuccess(t('design.newsletter.success')) : setError(t('design.newsletter.error'));
  //     })
  //     .catch(() => {
  //       setError(t('design.newsletter.error'));
  //     });
  // };

  // useEffect(() => {
  //   setIsNewsletterTriggeredLessThan24HoursAgo(
  //     Date.now() - parseInt(window.localStorage.getItem('newsletterTriggeredAt'), 10) < 86400000,
  //   );

  //   setTimeout(() => {
  //     setIsNewsletterOpened(true);
  //   }, 3000);

  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       const position = entry.boundingClientRect.top - window.innerHeight;

  //       if (position < 0 && Math.abs(position) < newsletterRef.current.getBoundingClientRect().height) {
  //         return;
  //       }

  //       if (entry.isIntersecting) {
  //         setIsFixedNewsletter(true);
  //       } else {
  //         setIsFixedNewsletter(false);
  //       }
  //     },
  //     {
  //       threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  //     },
  //   );

  //   observer.observe(footerRef.current);

  //   return () => observer.disconnect();
  // }, []);

  // useEffect(() => {
  //   const onScroll = () => {
  //     setScrollY(window.scrollY);
  //   };

  //   const openPopup =
  //     !isNewsletterForceClosed && (isNewsletterOpened || scrollY >= 1000) &&
  // !isNewsletterTriggeredLessThan24HoursAgo;

  //   setClasses(`${openPopup ? 'mod-opened' : ''} ${isFixedNewsletter ? 'mod-fixed' : ''}`);

  //   if (openPopup) {
  //     window.localStorage.setItem('newsletterTriggeredAt', Date.now().toString());
  //   }

  //   window.removeEventListener('scroll', onScroll);
  //   window.addEventListener('scroll', onScroll, { passive: true });

  //   return () => window.removeEventListener('scroll', onScroll);
  // }, [
  //   isNewsletterOpened,
  //   scrollY,
  //   isNewsletterForceClosed,
  //   isNewsletterTriggeredLessThan24HoursAgo,
  //   isFixedNewsletter,
  // ]);

  // const handleNewsletterClose = () => {
  //   setIsNewsletterForceClosed(true);
  // };

  const links = formatNavLinksFromComponent(footerComponent);
  const linkColumns = [];
  let currentColumn = 0;

  for (let i = 0; i < links.length; i += 1) {
    const link = links[i];

    if (link.columnSeparator) {
      currentColumn += 1;
    }

    if (!linkColumns[currentColumn]) {
      linkColumns[currentColumn] = [];
    }

    linkColumns[currentColumn].push(link);
  }

  const isExternalLink = (link: HeaderLinkLevel1) => {
    const pdfPattern = /\.pdf$/;

    return !!link.url.match(pdfPattern);
  };

  return (
    <>
      {/* <Newsletter ref={newsletterRef} className={classes}>
        <Container>
          <NewsletterLeft>
            <NewsletterTitle>{t('footer.newsletter.title')}</NewsletterTitle>
            <NewsletterText>{t('footer.newsletter.text')}</NewsletterText>
          </NewsletterLeft>
          <NewsletterRight>
            {success && <Message>{success}</Message>}
            {!success && (
              <>
                <NewsletterForm ref={newsletterFormRef} onSubmit={(e: any) => registerNewsletter(e)}>
                  <NewsletterInput
                    type="email"
                    ref={emailRef}
                    value={email}
                    placeholder={t('footer.newsletter.placeholder')}
                  />
                  <NewsletterButton type="submit">{t('footer.newsletter.button')}</NewsletterButton>
                </NewsletterForm>
                {error && <Message>{error}</Message>}
              </>
            )}
            <NewsletterUnsubscribe>
              {t('footer.newsletter.unsubscribe')}
              <LinkComponent href="https://www.clubic.com/politique_de_confidentialite">
                {t('footer.newsletter.unsubscribeAnchor')}
              </LinkComponent>
            </NewsletterUnsubscribe>
          </NewsletterRight>
          <NewsletterClose onClick={handleNewsletterClose}>&times;</NewsletterClose>
        </Container>
      </Newsletter> */}
      <FooterWrapper ref={footerRef}>
        <Container className="mod-column">
          <FooterLogo>
            <ClubicText width={140} height={37} color="white" />
          </FooterLogo>
          <FooterContent>
            <FooterLeft>
              <FooterTitle>{t('footer.title')}</FooterTitle>
              <FooterText>{t('footer.text')}</FooterText>
            </FooterLeft>
            <FooterRight>
              {linkColumns[1] && (
                <FooterList>
                  {linkColumns[1]?.map((column, index) => (
                    <FooterListItem key={index}>
                      <LinkComponent href={column.url} openInNewWindow={isExternalLink(column)}>
                        {column.title}
                      </LinkComponent>
                    </FooterListItem>
                  ))}
                </FooterList>
              )}
            </FooterRight>
          </FooterContent>
          <FooterSocials>
            <LinkComponent openInNewWindow href="https://www.facebook.com/Clubic/">
              <FooterSocialItem>
                <Facebook height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
            <LinkComponent openInNewWindow href="https://x.com/Clubic">
              <FooterSocialItem>
                <Twitter height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
            <LinkComponent openInNewWindow href="https://www.youtube.com/user/Clubic">
              <FooterSocialItem>
                <Youtube height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
            <LinkComponent openInNewWindow href="https://www.linkedin.com/company/clubic/">
              <FooterSocialItem>
                <Linkedin height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
            <LinkComponent openInNewWindow href="https://www.instagram.com/clubic_off/">
              <FooterSocialItem>
                <Instagram height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
            <LinkComponent openInNewWindow href="https://www.tiktok.com/@clubic_">
              <FooterSocialItem>
                <TikTok height={20} width={20} color={currentTheme.palette.blackAlt} />
              </FooterSocialItem>
            </LinkComponent>
          </FooterSocials>

          <FooterLegal>
            <FooterCopyright>{t('footer.copyright', { year: copyrightYear.getFullYear() })}</FooterCopyright>
            {linkColumns[0] && (
              <FooterLegalLinks>
                {linkColumns[0]?.map((column, index) => (
                  <FooterLegalLink key={index}>
                    <LinkComponent href={column.url} openInNewWindow={isExternalLink(column)}>
                      {column.title}
                    </LinkComponent>
                  </FooterLegalLink>
                ))}
              </FooterLegalLinks>
            )}
          </FooterLegal>
        </Container>
      </FooterWrapper>
    </>
  );
};
