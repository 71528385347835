import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface SymbolProps {
  className: string;
  color: string;
  fill: number;
  weight: number;
  size: number;
}

const Symbol = styled.span`
  color: ${({ color }: SymbolProps) => color};
  display: inline-block;
  font-weight: ${({ weight }: SymbolProps) => weight};
  font-size: ${({ size }: SymbolProps) => size}px;
  line-height: 1;
  width: 1em;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-variation-settings: ${({ fill }: SymbolProps) => (fill ? "'FILL' 1" : 'none')};
`;

export const MaterialSymbol = ({
  className,
  symbol,
  color,
  fill,
  weight,
  size,
  Component,
  lazy = true,
}: {
  className?: string;
  Component?: React.JSXElementConstructor<any>;
  symbol: string;
  color?: string;
  fill?: number;
  weight?: number;
  size?: number;
  lazy?: boolean;
}) => {
  const [style, setStyle] = useState(lazy ? {} : { fontFamily: 'Material Symbols Outlined' });
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (lazy) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !('fontFamily' in style)) {
              setStyle({ fontFamily: 'Material Symbols Outlined' });
              observer.unobserve(entry.target);
            }
          });
        },
        {
          rootMargin: '-75px 0px 0px 0px',
        },
      );

      observer.observe(ref.current);
    }
  }, [setStyle, style, lazy]);

  if (Component) {
    return (
      <Component ref={ref} className={className} style={style}>
        {'fontFamily' in style ? symbol : null}
      </Component>
    );
  }

  return (
    <Symbol ref={ref} className={className} color={color} fill={fill} weight={weight} size={size} style={style}>
      {'fontFamily' in style ? symbol : null}
    </Symbol>
  );
};
